import React from "react"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import SEO from "../../../components/seo"
// import { Helmet } from 'react-helmet'
import TwoColumn from "../../../components/two-column"
import List from "../../../components/compare-list"

const bannerContent = {
  title: "Our inconspicuously named access control revolution.",
  description: [
    {
      text: "With Feature Tags, it's possible to immediately identify what a customer should have access to and where they should be without needing to know what plan they're on. Honestly, it's kind of a big deal."
    }
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today',
      isExternal: true,
    },
    {
      scheduleButton: true,
    }
  ],
  tags: [
    {
      class: 'adoric_video_featuretags',
      text: "Feature Tags"
    }
  ],
  isBanner: true,
  sectionFeaturetag: true,
  image: require('../../../images/feature-tags.svg'),
  svg: true,
  imageName: 'plan-action svg',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
  featureTagButton: true
}

const listContent = {
  bgColor: 'grey',
  content: [
    {
      title: "Create as many feature tags as you need to run your business.",
      image: 'business.png',
      list: [
        {
          subtitle: "As broad or granular as you require",
          text: <>Feature tags can represent big, huge features <strong>(#app)</strong> or tiny little features <strong>(#leftmenubutton)</strong> depending on what makes sense for your business.</>
        },
        {
          subtitle: "As many as you'd like ",
          text: "There's no limits on how many feature tags you can associate with a particular customer. Need to list out 400 locations they can access? Go ahead."
        },
        {
          subtitle: "Link to plans, add-ons and allowances",
          text: "You can create groups of distinct feature tags based on the exact make-up of each customers plan for very specific control."
        },
      ],
      link: [
        {
          textLink: "OFFPEAK",
        },
        {
          textLink: "YOGA",
        },
        {
          textLink: "TOWELHIRE",
        }
      ]
    },
    {
      title: "Simple to manage and update at any time.",
      image: 'media-manage.png',
      list: [
        {
          subtitle: "With the API ",
          text: "Make bulk updates to feature tags with the API adding new tags to thousands of customers accounts at once."
        },
        {
          subtitle: "In the control panel ",
          text: <>Give one customer extra feature tags on an ad-hoc basis (like <strong>#vip</strong> or <strong>#disabledentrance</strong>) with no complex configuration.</>
        },
        {
          subtitle: "Make plan level changes to feature tags whenever it makes sense ",
          text: <>Like to roll out new features, or let certain cohorts of customers try something out (like <strong>#imax</strong> when you want to run a free IMAX weekend).</>
        },
      ],
      link: [
        {
          textLink: "FREEPOPCORN",
        },
        {
          textLink: "3DFILMS",
        },
      ]
    },
  ]
}

const featureTag = () => {
  return(
    <Layout className="feature-tag">
      <SEO 
        title="Feature tags | Billsby" 
        description="Create feature tags to manage permissions and access controls with our customizable online recurring billing and subscription management software | Billsby" 
        url="https://www.billsby.com/product/integrations/feature-tags"
      />

      <TwoColumn content={bannerContent}/>
      <div className="explaining-story">
        <div classname="container">
          <h2>Explaining this one properly takes a little story</h2>
            <div className="bond-paper">
              <p>Before I started working at Billsby I worked for a big ISP. The type that sold basically only three things - slow broadband, fast broadband and really fast broadband. When you think of a business like that, the idea of plan centric billing makes a lot of sense on the surface of things. If you know the customer has the fast broadband plan, you gave them fast broadband.</p>

              <p>But in reality, underpinning the three basic features - those broadband speeds - there were thousands of plans, deals, bundles, short term promotions, legacy offers, ad-hoc upgrades, employee and affinity plans... so much complexity. The kind that makes plan centric billing a nightmare.</p>

              <p> Because when you want to introduce a new plan, all of your other systems have to be updated and aligned to understand what that plan actually is. All it takes is one system to be misaligned - a release to fail, human error - and then your customer either gets provisioned wrong or isn't provisioned at all. And adding a new plan is a huge cost too - as all those teams rally around to set everything up. </p>

              <p>I often thought that in an ideal world, what we really needed was plan agnostic billing - so someone from marketing could create a brand new plan overnight and just say it had fast broadband, unlimited calls and voicemail, and then all the systems would stop asking "Which plan is the user on?" and instead simply "Does this user have access to fast broadband?".</p>

              <p>So when we built Billsby, and we wanted to make it the <Link to="/product/developers">best possible billing platform for developers</Link> there was no doubt in my mind that this simple feature, without a clever marketing name, could really revolutionise billing.</p>

              <p> From a confused mess, to just a simple tag. Feature tags come standard for every Billsby customer.</p>

              <div className="feature-sign-holder">
                <img src={require("../../../images/morgan.svg")} alt="morgan" />

                <div className="sign-holder">
                  <img className="sign-logo" src={require("../../../images/feature-tags-letter-signature.svg")} alt="morgan" />
                  <p>Head of Product and Business Development</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      <List compare={listContent}/>
    </Layout>
  )
}

export default featureTag